















































































































































































import Vue from 'vue'
import api from '@/api'

import { mapGetters } from 'vuex'
import { Accessors } from 'vue/types/options'
import { getValidators } from '@/mixins/FormMixin'
import { BackendError, Config, ContactUs, VFormRef } from '@/types'

type NewContactUs = Pick<
	ContactUs,
	'vFullName' | 'vEmail' | 'vSubject' | 'vMobilePhone' | 'tComment'
>

export default Vue.extend({
	name: 'ContactUs',
	data: () => ({
		isBot: '',
		rules: getValidators('required', 'email', 'max'),
		form: {
			isValid: false,
			isLoading: false,
			errors: {} as BackendError,
			data: {
				vFullName: '',
				vEmail: '',
				vSubject: '',
				vMobilePhone: '',
				tComment: '',
			} as NewContactUs,
		},
	}),
	computed: {
		...({
			...mapGetters(['$config']),
		} as Accessors<{
			$config: Config
		}>),
	},
	methods: {
		async onSubmit() {
			const formRef = this.$refs.formRef as VFormRef
			if (!formRef.validate()) return

			if (this.isBot) {
				formRef.reset()
				return this.$toast.error(
					"Great! You have proven to be a bot! I've destroyed the form the page, and acted like you successfully sent the form."
				)
			}

			this.form.isLoading = true
			const [err] = await api.post('/contact-us', this.form.data)
			this.form.isLoading = false

			if (err) {
				return this.$toast.error(
					err.message ||
						'Error while sending your message, Please try again later.'
				)
			}

			formRef.reset()
			this.$toast.success(
				'Your message has been sent successfully, We will get back to you soon.'
			)
		},
	},
})
